import React from 'react';
import './footer.css'; // Create this CSS file to style the footer

const Footer: React.FC = () => {
  return (

        <div style={{ borderTopStyle: 'solid', borderTopColor: '#323232', backgroundColor: '#292929', width: '100%', height: 'auto', position: 'relative', left: 0, right: 0, top: '2vh' }}>
          <div className="centerDiv" style={{ lineHeight: '80%', paddingBottom: '2vh', paddingTop: '2vh', width: '100%' }}>
            <p style={{ fontSize: '11px', lineHeight: '100%' }}>
            <a className="footerLinks" href="https://store.steampowered.com/app/1446340/Shaype/" target="_blank" rel="noopener"><i className="fa-brands fa-steam"></i></a>
            | <a className="footerLinks" href="https://discord.gg/ZADcjxhw4q" target="_blank" rel="noopener"><i className="fa-brands fa-discord" aria-hidden="true"></i></a> 
            | <a className="footerLinks" href="https://www.patreon.com/sinkingshipstudios" target="_blank" rel="noopener"><i className="fa-brands fa-patreon"></i></a>
            | <a className="footerLinks" href="https://www.instagram.com/sinkingshipstudiosla/" target="_blank" rel="noopener"><i className="fa-brands fa-instagram"></i></a>
            | <a className="footerLinks" href="https://x.com/SinkingShipLA" target="_blank" rel="noopener"><i className="fa-brands fa-x-twitter"></i></a>
            | <a className="footerLinks" href="https://www.youtube.com/@sinkingshipstudios-la" target="_blank" rel="noopener"><i className="fa-brands fa-youtube"></i></a>
              {/* <a className="footerLinks" href="https://store.steampowered.com/app/1446340/Shaype/">Steam</a> | <a className="footerLinks" href="https://www.patreon.com/sinkingshipstudios">Patreon</a> | <a className="footerLinks" href="https://discord.gg/ZADcjxhw4q">Discord</a> | <a className="footerLinks" href="https://twitter.com/SinkingShipLA">X</a> */}
            </p>
            <p style={{ fontSize: '11px', lineHeight: '100%' }}> <a className="footerLinks" href="/privacy-policy" target="_blank" rel="noopener">Privacy</a> | <a className="footerLinks" href="/terms-and-conditions" target="_blank">Terms</a></p>
            {/* <p style={{ fontSize: '11px', lineHeight: '100%' }}>Privacy | Legal | Terms | Cookies</p>*/}
          </div>
        </div>

  );
}

export default Footer;
