import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import FlexboxLayout from './components/flexboxLayout';
//import Preloader from './components/preloader';
import './App.css';



//import Footer from 'components/footer'; // Import the Footer component
//import HeroCarousel from 'components/carousel';
//import MusicPlayer from 'components/music';
//import GalleryIMG from 'components/gallery';

import Home from './pages/Home';   // Now coming from the pages folder
import Gallery from './pages/Gallery';
import Listen from './pages/Listen';
import Team from './pages/Team';
import Terms from './pages/Terms-Conditions';
import Privacy from './pages/Privacy-Policy';

//import ShaypeLogo from 'assets/images/home_images/SHAYPE.png'; // Import the Footer component
//const HeroVideo_MP4 = require('assets/videos/HeroVideo.mp4');
//const HeroVideoThumb = require('assets/images/home_images/HeroVideoThumb.jpg');




const App: React.FC = () => {
  return (
    <Router>

      <Routes>
        <Route path="/" element={<Home />} aria-label="Go to Home"/>
        <Route path="/gallery" element={<Gallery />} aria-label="See some of our screenshots"/>
        <Route path="/music" element={<Listen />} aria-label="Listen to more of our tracks"/>
        <Route path="/about-us" element={<Team />} aria-label="Meet the team working at Sinking Ship Studios"/>
        <Route path="/terms-and-conditions" element={<Terms />} aria-label="Read our terms and conditions"/>
        <Route path="/privacy-policy" element={<Privacy />} aria-label="Read our privacy policy"/>
      </Routes>

    </Router>
    
  );
};

export default App;