// src/pages/Legal.tsx
import React from 'react';

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component
import Footer from 'components/footer'; // Import the Footer component


const Legal: React.FC = () => {
  return (
    <div className="App">
        <NavBarTop theme="solid" /> {/* Add the Navbar component here */}
        <NavBarMain theme="solid" />{/* Add the Navbar component here */} 



        <section className="paragraph info">
            <h1>Terms of Service</h1>
            {/*<p>Below are a few mixes of tracks from our game <a href="https://store.steampowered.com/app/1446340/Shaype/" target="_blank" rel="noopener noreferrer"><b>Shaype</b></a></p>*/}
            <hr className="hr-short" />
            <br></br>
        </section>

        <section className="paragraph info">
            <p><strong>Effective Date:</strong> November 19, 2024</p>
            <p>Welcome to <strong>Sinking Ship Studios</strong> (“Company,” “we,” “our,” or “us”)! These Terms of Service (“Terms”) govern your access to and use of our website, <a href="https://sinking-ship-studios.com" target="_blank" rel="noopener noreferrer">https://sinking-ship-studios.com</a> (“Website”), and any related services, features, or content offered by us (collectively, the “Services”). By accessing or using our Website, you agree to these Terms. If you do not agree, please do not use our Website.</p>
            
            <h2>1. Eligibility</h2>
            <p>You must be at least 13 years old (or the minimum legal age in your jurisdiction) to use our Website. By accessing our Website, you represent and warrant that you meet these eligibility requirements.</p>
            
            <h2>2. Use of Services</h2>
            <p>You agree to use our Website only for lawful purposes and in compliance with these Terms. Prohibited activities include but are not limited to:</p>
            <ul>
                <li>Engaging in fraudulent, abusive, or illegal activities.</li>
                <li>Disrupting the functionality of the Website.</li>
                <li>Violating the intellectual property rights of the Company or others.</li>
            </ul>
            <p>We use third-party analytics tools such as <strong>Google Analytics</strong> and <strong>Hotjar</strong> to monitor and improve the Website's performance and functionality. By using the Website, you acknowledge and consent to these tools processing your interactions with the Website.</p>
            
            <h2>3. Intellectual Property</h2>
            <p>All content, trademarks, and materials available on the Website are the property of Sinking Ship Studios or our licensors. Unauthorized use of our intellectual property is strictly prohibited.</p>
            
            <h2>4. Third-Party Links</h2>
            <p>Our Website may include links to third-party websites. We are not responsible for the content, policies, or practices of any third-party sites. Accessing third-party sites is at your own risk.</p>
            
            <h2>5. Disclaimer of Warranties</h2>
            <p>Our Website is provided “as is” and “as available,” without warranties of any kind, either express or implied. We do not guarantee that the Website will be error-free, secure, or uninterrupted.</p>
            
            <h2>6. Limitation of Liability</h2>
            <p>To the fullest extent permitted by law, Sinking Ship Studios shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of our Website.</p>
            
            <h2>7. Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles.</p>
            
            <h2>8. Changes to These Terms</h2>
            <p>We reserve the right to update these Terms at any time. Changes will be effective upon posting to our Website. Continued use of the Website after changes constitutes acceptance of the updated Terms.</p>
            
            <h2>9. Contact Us</h2>
            <p>If you have questions about these Terms, please contact us at:</p>
            <ul>
                <li><strong>Email:</strong> <a href="mailto:sinkingshipstudiosla@gmail.com">sinkingshipstudiosla@gmail.com</a></li>
            </ul>
            <br></br>
        </section>


        <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default Legal;
