import React, { useEffect, useRef } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import './gallery.css';

import beachCampTunnel_HD from 'assets/images/gallery_images/beachCampTunnel.jpg';
import beachCampTunnel_Low_WEBP from 'assets/images/gallery_images/thumbnails/beachCampTunnel_thumb.webp';
import beachCampTunnel_Low_JPG from 'assets/images/gallery_images/thumbnails/beachCampTunnel_thumb.jpg';

import firstCamp_HD from 'assets/images/gallery_images/firstCamp.jpg';
import firstCamp_Low_WEBP from 'assets/images/gallery_images/thumbnails/firstCamp_thumb.webp';
import firstCamp_Low_JPG from 'assets/images/gallery_images/thumbnails/firstCamp_thumb.jpg';

import FortressDawn_HD from 'assets/images/gallery_images/Fortress_Dawn.jpg';
import FortressDawn_Low_WEBP from 'assets/images/gallery_images/thumbnails/Fortress_Dawn_thumb.webp';
import FortressDawn_Low_JPG from 'assets/images/gallery_images/thumbnails/Fortress_Dawn_thumb.jpg';

import GearCropped_HD from 'assets/images/gallery_images/GearCropped.jpg';
import GearCropped_Low_WEBP from 'assets/images/gallery_images/thumbnails/GearCropped_thumb.webp';
import GearCropped_Low_JPG from 'assets/images/gallery_images/thumbnails/GearCropped_thumb.jpg';

import GrasslandsSunset_HD from 'assets/images/gallery_images/grasslands_Sunset.jpg';
import GrasslandsSunset_Low_WEBP from 'assets/images/gallery_images/thumbnails/grasslands_Sunset_thumb.webp';
import GrasslandsSunset_Low_JPG from 'assets/images/gallery_images/thumbnails/grasslands_Sunset_thumb.jpg';

import GrasslandsSunset_NEW_HD from 'assets/images/gallery_images/grasslands_Sunset_new.jpg';
import GrasslandsSunset_NEW_Low_WEBP from 'assets/images/gallery_images/thumbnails/grasslands_Sunset_new_thumb.webp';
import GrasslandsSunset_NEW_Low_JPG from 'assets/images/gallery_images/thumbnails/grasslands_Sunset_new_thumb.jpg';

import newClouds_HD from 'assets/images/gallery_images/newClouds.jpg';
import newClouds_Low_WEBP from 'assets/images/gallery_images/thumbnails/newClouds_thumb.webp';
import newClouds_Low_JPG from 'assets/images/gallery_images/thumbnails/newClouds_thumb.jpg';

import newGrass_HD from 'assets/images/gallery_images/newGrass.jpg';
import newGrass_Low_WEBP from 'assets/images/gallery_images/thumbnails/newGrass_thumb.webp';
import newGrass_Low_JPG from 'assets/images/gallery_images/thumbnails/newGrass_thumb.jpg';

import sadman_HD from 'assets/images/gallery_images/sadman.jpg';//FIX THIS TO JPG AND MAKE VARIANTS
import sadman_Low_WEBP from 'assets/images/gallery_images/thumbnails/sadman_thumb.webp';
import sadman_Low_JPG from 'assets/images/gallery_images/thumbnails/sadman_thumb.jpg';

import seamadTunnel_HD from 'assets/images/gallery_images/seamadTunnel.jpg';
import seamadTunnel_Low_WEBP from 'assets/images/gallery_images/thumbnails/seamadTunnel_thumb.webp';
import seamadTunnel_Low_JPG from 'assets/images/gallery_images/thumbnails/seamadTunnel_thumb.jpg';

import combatDemo_HD from 'assets/images/gallery_images/shaype_combatDemo.jpg';
import combatDemo_Low_WEBP from 'assets/images/gallery_images/thumbnails/shaype_combatDemo_thumb.webp';
import combatDemo_Low_JPG from 'assets/images/gallery_images/thumbnails/shaype_combatDemo_thumb.jpg';

import lightPreview_HD from 'assets/images/gallery_images/shaype_lightPreview.jpg';
import lightPreview_Low_WEBP from 'assets/images/gallery_images/thumbnails/shaype_lightPreview_thumb.webp';
import lightPreview_Low_JPG from 'assets/images/gallery_images/thumbnails/shaype_lightPreview_thumb.jpg';

import snowTest_HD from 'assets/images/gallery_images/snowTest.jpg';
import snowTest_Low_WEBP from 'assets/images/gallery_images/thumbnails/snowTest_thumb.webp';
import snowTest_Low_JPG from 'assets/images/gallery_images/thumbnails/snowTest_thumb.jpg';

import statuesDay_HD from 'assets/images/gallery_images/statuesDay.jpg';
import statuesDay_Low_WEBP from 'assets/images/gallery_images/thumbnails/statuesDay_thumb.webp';
import statuesDay_Low_JPG from 'assets/images/gallery_images/thumbnails/statuesDay_thumb.jpg';

import statuesNight_HD from 'assets/images/gallery_images/statuesNight.jpg';
import statuesNight_Low_WEBP from 'assets/images/gallery_images/thumbnails/statuesNight_thumb.webp';
import statuesNight_Low_JPG from 'assets/images/gallery_images/thumbnails/statuesNight_thumb.jpg';

import theDuke_HD from 'assets/images/gallery_images/theDuke.jpg';
import theDuke_Low_WEBP from 'assets/images/gallery_images/thumbnails/theDuke_thumb.webp';
import theDuke_Low_JPG from 'assets/images/gallery_images/thumbnails/theDuke_thumb.jpg';

import TheIsland_HD from 'assets/images/gallery_images/TheIsland.jpg';
import TheIsland_Low_WEBP from 'assets/images/gallery_images/thumbnails/TheIsland_thumb.webp';
import TheIsland_Low_JPG from 'assets/images/gallery_images/thumbnails/TheIsland_thumb.jpg';

import theWall_HD from 'assets/images/gallery_images/theWall.jpg';
import theWall_Low_WEBP from 'assets/images/gallery_images/thumbnails/theWall_thumb.webp';
import theWall_Low_JPG from 'assets/images/gallery_images/thumbnails/theWall_thumb.jpg';

import VolumetricClouds_Dawn_HD from 'assets/images/gallery_images/VolumetricClouds_Dawn.jpg';
import VolumetricClouds_Dawn_Low_WEBP from 'assets/images/gallery_images/thumbnails/VolumetricClouds_Dawn_thumb.webp';
import VolumetricClouds_Dawn_Low_JPG from 'assets/images/gallery_images/thumbnails/VolumetricClouds_Dawn_thumb.jpg';

import VolumetricClouds_Midnight_HD from 'assets/images/gallery_images/VolumetricClouds_Midnight.jpg';
import VolumetricClouds_Midnight_Low_WEBP from 'assets/images/gallery_images/thumbnails/VolumetricClouds_Midnight_thumb.webp';
import VolumetricClouds_Midnight_Low_JPG from 'assets/images/gallery_images/thumbnails/VolumetricClouds_Midnight_thumb.jpg';

import VolumetricClouds_Sunset_HD from 'assets/images/gallery_images/VolumetricClouds_Sunset.jpg';
import VolumetricClouds_Sunset_Low_WEBP from 'assets/images/gallery_images/thumbnails/VolumetricClouds_Sunset_thumb.webp';
import VolumetricClouds_Sunset_Low_JPG from 'assets/images/gallery_images/thumbnails/VolumetricClouds_Sunset_thumb.jpg';






import caverns_camp_1_HD from 'assets/images/gallery_images/caverns_camp_1.jpg';
import caverns_camp_1_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_camp_1_thumb.webp';
import caverns_camp_1_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_camp_1_thumb.jpg';

import caverns_camp_2_HD from 'assets/images/gallery_images/caverns_camp_2.jpg';
import caverns_camp_2_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_camp_2_thumb.webp';
import caverns_camp_2_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_camp_2_thumb.jpg';

import caverns_clutter_HD from 'assets/images/gallery_images/caverns_clutter.jpg';
import caverns_clutter_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_clutter_thumb.webp';
import caverns_clutter_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_clutter_thumb.jpg';

import caverns_creatures_HD from 'assets/images/gallery_images/caverns_creatures.jpg';
import caverns_creatures_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_creatures_thumb.webp';
import caverns_creatures_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_creatures_thumb.jpg';

import caverns_liberty_1_HD from 'assets/images/gallery_images/caverns_liberty_1.jpg';
import caverns_liberty_1_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_liberty_1_thumb.webp';
import caverns_liberty_1_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_liberty_1_thumb.jpg';

import caverns_liberty_2_HD from 'assets/images/gallery_images/caverns_liberty_2.jpg';
import caverns_liberty_2_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_liberty_2_thumb.webp';
import caverns_liberty_2_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_liberty_2_thumb.jpg';

import caverns_loot_HD from 'assets/images/gallery_images/caverns_loot.jpg';
import caverns_loot_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_loot_thumb.webp';
import caverns_loot_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_loot_thumb.jpg';

import caverns_stalk_HD from 'assets/images/gallery_images/caverns_stalk.jpg';
import caverns_stalk_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_stalk_thumb.webp';
import caverns_stalk_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_stalk_thumb.jpg';

import caverns_waystone_HD from 'assets/images/gallery_images/caverns_waystone.jpg';
import caverns_waystone_Low_WEBP from 'assets/images/gallery_images/thumbnails/caverns_waystone_thumb.webp';
import caverns_waystone_Low_JPG from 'assets/images/gallery_images/thumbnails/caverns_waystone_thumb.jpg';

const Gallery: React.FC = () => {

    const galleryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Lazy load observer setup
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const imgElement = entry.target as HTMLImageElement;
              console.log(`Trying to load image: ${imgElement.alt}`);
              imgElement.src = imgElement.dataset.src || '';
    
              imgElement.onload = () => {
                console.log(`Load complete for image: ${imgElement.alt}`);
                imgElement.classList.add('loaded'); // Add class to mark it as loaded
              };
    
              observer.unobserve(imgElement); // Stop observing once loaded
            }
          });
        }, {
          threshold: 0.1,
        });
    
        // Attach the observer to each image
        const imgElements = galleryRef.current?.querySelectorAll('img[data-src]');
        imgElements?.forEach((img) => observer.observe(img));
    
        // Initialize Fancybox
        Fancybox.bind('[data-fancybox="gallery"]', {});
    
        return () => {
          Fancybox.destroy(); // Cleanup on unmount
          observer.disconnect(); // Cleanup the observer
        };
      }, []);

    return (
    <div className="gallery-container" ref={galleryRef}>
        {/* Using picture for WebP fallback */}
        <a data-fancybox="gallery" href={caverns_camp_1_HD} >
            <picture>
                <source type="image/webp" data-src={caverns_camp_1_Low_WEBP} />
                <img data-src={caverns_camp_1_Low_JPG} alt="caverns_camp_1" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_camp_2_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={caverns_camp_2_Low_WEBP} />
                <img data-src={caverns_camp_2_Low_JPG} alt="caverns_camp_2" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_clutter_HD} >
            <picture>
                <source type="image/webp" data-src={caverns_clutter_Low_WEBP} />
                <img data-src={caverns_clutter_Low_JPG} alt="caverns_clutter" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_creatures_HD} >
            <picture>
                <source type="image/webp" data-src={caverns_creatures_Low_WEBP} />
                <img data-src={caverns_creatures_Low_JPG} alt="caverns_creatures" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_liberty_1_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={caverns_liberty_1_Low_WEBP} />
                <img data-src={caverns_liberty_1_Low_JPG} alt="caverns_liberty_1" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_liberty_2_HD} >
            <picture>
                <source type="image/webp" data-src={caverns_liberty_2_Low_WEBP} />
                <img data-src={caverns_liberty_2_Low_JPG} alt="caverns_liberty_2" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_loot_HD} >
            <picture>
                <source type="image/webp" data-src={caverns_loot_Low_WEBP} />
                <img data-src={caverns_loot_Low_JPG} alt="caverns_loot" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_stalk_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={caverns_stalk_Low_WEBP} />
                <img data-src={caverns_stalk_Low_JPG} alt="caverns_stalk" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={caverns_waystone_HD} >
            <picture>
                <source type="image/webp" data-src={caverns_waystone_Low_WEBP} />
                <img data-src={caverns_waystone_Low_JPG} alt="caverns_waystone" />
            </picture>
        </a>











        <a data-fancybox="gallery" href={beachCampTunnel_HD} >
            <picture>
                <source type="image/webp" data-src={beachCampTunnel_Low_WEBP} />
                <img data-src={beachCampTunnel_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={firstCamp_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={firstCamp_Low_WEBP} />
                <img data-src={firstCamp_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={FortressDawn_HD} >
            <picture>
                <source type="image/webp" data-src={FortressDawn_Low_WEBP} />
                <img data-src={FortressDawn_Low_JPG} alt="Fortress Dawn" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={GearCropped_HD} >
            <picture>
                <source type="image/webp" data-src={GearCropped_Low_WEBP} />
                <img data-src={GearCropped_Low_JPG} alt="Gear Cropped" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={GrasslandsSunset_HD} >
            <picture>
                <source type="image/webp" data-src={GrasslandsSunset_Low_WEBP} />
                <img data-src={GrasslandsSunset_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={GrasslandsSunset_NEW_HD} >
            <picture>
                <source type="image/webp" data-src={GrasslandsSunset_NEW_Low_WEBP} />
                <img data-src={GrasslandsSunset_NEW_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={newClouds_HD} >
            <picture>
                <source type="image/webp" data-src={newClouds_Low_WEBP} />
                <img data-src={newClouds_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={newGrass_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={newGrass_Low_WEBP} />
                <img data-src={newGrass_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={sadman_HD} >
            <picture>
                <source type="image/webp" data-src={sadman_Low_WEBP} />
                <img data-src={sadman_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={seamadTunnel_HD} >
            <picture>
                <source type="image/webp" data-src={seamadTunnel_Low_WEBP} />
                <img data-src={seamadTunnel_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={combatDemo_HD} >
            <picture>
                <source type="image/webp" data-src={combatDemo_Low_WEBP} />
                <img data-src={combatDemo_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={VolumetricClouds_Sunset_HD} className="large-image" >
            <picture>
                <source type="image/webp" data-src={VolumetricClouds_Sunset_Low_WEBP} />
                <img data-src={VolumetricClouds_Sunset_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={lightPreview_HD} >
            <picture>
                <source type="image/webp" data-src={lightPreview_Low_WEBP} />
                <img data-src={lightPreview_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={snowTest_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={snowTest_Low_WEBP} />
                <img data-src={snowTest_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={statuesDay_HD} >
            <picture>
                <source type="image/webp" data-src={statuesDay_Low_WEBP} />
                <img data-src={statuesDay_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={statuesNight_HD} >
            <picture>
                <source type="image/webp" data-src={statuesNight_Low_WEBP} />
                <img data-src={statuesNight_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={theDuke_HD} >
            <picture>
                <source type="image/webp" data-src={theDuke_Low_WEBP} />
                <img data-src={theDuke_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={TheIsland_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={TheIsland_Low_WEBP} />
                <img data-src={TheIsland_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={theWall_HD} >
            <picture>
                <source type="image/webp" data-src={theWall_Low_WEBP} />
                <img data-src={theWall_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={VolumetricClouds_Dawn_HD} >
            <picture>
                <source type="image/webp" data-src={VolumetricClouds_Dawn_Low_WEBP} />
                <img data-src={VolumetricClouds_Dawn_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={VolumetricClouds_Midnight_HD} >
            <picture>
                <source type="image/webp" data-src={VolumetricClouds_Midnight_Low_WEBP} />
                <img data-src={VolumetricClouds_Midnight_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
        
    </div>
    );
    };

export default Gallery;
