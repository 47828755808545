// src/pages/Legal.tsx
import React from 'react';

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component
import Footer from 'components/footer'; // Import the Footer component


const Legal: React.FC = () => {
  return (
    <div className="App">
        <NavBarTop theme="solid" /> {/* Add the Navbar component here */}
        <NavBarMain theme="solid" />{/* Add the Navbar component here */} 



        <section className="paragraph info">
            <h1>Privacy Policy</h1>
            {/*<p>Below are a few mixes of tracks from our game <a href="https://store.steampowered.com/app/1446340/Shaype/" target="_blank" rel="noopener noreferrer"><b>Shaype</b></a></p>*/}
            <hr className="hr-short" />
            <br></br>
        </section>

        <section className="paragraph info">
            <p><strong>Effective Date:</strong> November 19, 2024</p>
            <p><strong>Sinking Ship Studios</strong> ("Company," "we," "our," or "us") values your privacy. This Privacy Policy explains how we collect, use, and protect information when you visit our website, <a href="https://sinking-ship-studios.com" target="_blank" rel="noopener noreferrer">https://sinking-ship-studios.com</a> ("Website"). By using our Website, you agree to the practices described in this policy. If you do not agree, please do not use the Website.</p>
            
            <h2>1. Information We Collect</h2>
            <p>When you visit our Website, we may collect the following information via <strong>Google Analytics</strong> and <strong>Hotjar</strong>:</p>
            <ul>
                <li>IP address</li>
                <li>Geographic location</li>
                <li>Browser type and version</li>
                <li>Operating system</li>
                <li>Pages viewed, time spent on the Website, and navigation paths</li>
                <li>Interactions with the Website (e.g., clicks, scrolls, and form inputs)</li>
                <li>Referring website or source</li>
                <li>Other technical data permitted by Google Analytics and Hotjar</li>
            </ul>
            
            <h2>2. How We Use the Information</h2>
            <p>The data we collect is used for the following purposes:</p>
            <ul>
                <li>To understand where our Website traffic comes from</li>
                <li>To analyze user behavior and interactions with the Website</li>
                <li>To improve the functionality, design, and content of our Website</li>
                <li>To develop marketing strategies</li>
            </ul>
            <p>We use this information internally and do not share it with third parties.</p>
            
            <h2>3. Cookies and Tracking</h2>
            <p>We use cookies and similar tracking technologies to enhance your experience on our Website. Cookies may be used for:</p>
            <ul>
                <li>Analytics purposes to track and measure Website performance</li>
                <li>Recording user interactions (via Hotjar) to analyze and optimize the user experience</li>
                <li>Remembering user preferences</li>
            </ul>
            <p>You can manage or disable cookies through your browser settings. However, some features of our Website may not function properly without cookies.</p>
            
            <h2>4. Hotjar Usage</h2>
            <p>We use Hotjar to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand user behavior (e.g., how much time they spend on which pages, which links they choose to click, etc.) and enables us to build and maintain our Website with user feedback. Hotjar uses cookies and other technologies to collect data on users' behavior and their devices, including:</p>
            <ul>
                <li>IP address (captured and stored in anonymized form)</li>
                <li>Device screen size</li>
                <li>Device type (unique device identifiers), operating system, and browser type</li>
                <li>Geographic location (country only)</li>
                <li>Preferred language for viewing our Website</li>
            </ul>
            <p>Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match it with additional data on an individual user. For more details, please see <a href="https://www.hotjar.com/legal/policies/privacy/" target="_blank" rel="noopener noreferrer">Hotjar's Privacy Policy</a>.</p>
            
            <h2>5. Data Storage</h2>
            <p>We do not directly save or store any user data collected by Google Analytics or Hotjar. All information is processed and stored by these services. We recommend reviewing the <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Privacy Policy</a> and <a href="https://www.hotjar.com/legal/policies/privacy/" target="_blank" rel="noopener noreferrer">Hotjar Privacy Policy</a> for details about their data handling practices.</p>
            
            <h2>6. Children’s Privacy</h2>
            <p>Our Website is not directed to individuals under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that such data has been collected, we will delete it promptly.</p>
            
            <h2>7. User Rights</h2>
            <p>While we do not store data directly, users can take the following actions:</p>
            <ul>
                <li>Disable cookies in their browser settings to prevent tracking.</li>
                <li>Use Google's <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">opt-out browser add-on</a> to stop Google Analytics from collecting their data.</li>
                <li>Opt-out of Hotjar tracking by following instructions on <a href="https://www.hotjar.com/legal/compliance/opt-out/" target="_blank" rel="noopener noreferrer">Hotjar's Opt-Out page</a>.</li>
            </ul>
            
            <h2>8. International Users</h2>
            <p>Our Website is accessible to users worldwide. By using our Website, you consent to the processing of your data as described in this Privacy Policy, in accordance with the laws of the United States.</p>
            
            <h2>9. Changes to This Policy</h2>
            <p>We may update this Privacy Policy from time to time. Changes will be effective upon posting to the Website. Continued use of the Website after changes signifies your acceptance of the updated policy.</p>
            
            <h2>10. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
            <ul>
                <li><strong>Email:</strong> <a href="mailto:sinkingshipstudiosla@gmail.com">sinkingshipstudiosla@gmail.com</a></li>
            </ul>
            <br></br>
        </section>


        <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default Legal;
