import React from 'react';

const fallBackArt = require('assets/images/team_art/noProfilPic.jpg');

// Define the types for your props
interface ArtistPopupProps {
  artist: {
    name: string;
    title: string;
    image: {
      webp: string;
      jpg: string;
      thumb: string;
    }
    contacts: Array<{
      icon: string;
      source: string;
      link: string;
    }>;
    info: string;
  };

  onClose: () => void; // Function to close the popup
}

const ArtistPopup: React.FC<ArtistPopupProps> = ({ artist, onClose }) => {
  return (
    <div className="artist-popup">
      <div className="artist-card-popup">
        <button className="close-popup" onClick={onClose} aria-label="Close Button">X</button>
        <h2 className="artist-name">{artist.name}</h2>
        <h3 className="artist-title">{artist.title}</h3>
        <picture>
          {/* WebP format (used if browser supports WebP) */}
          <source srcSet={artist.image.webp} type="image/webp" />
          {/* JPG format (fallback if WebP is not supported) */}
          <source srcSet={artist.image.jpg} type="image/jpeg" />
          {/* Fallback image element with onError fallback handling */}
          <img
            src={artist.image.jpg} 
            alt={artist.name}
            onError={(e) => (e.currentTarget.src = fallBackArt)}
          />
        </picture>
        <div className="contacts">
          {artist.contacts.map((contact, idx) => (
            <div key={idx} className="contact">
              <i className={`contact-icon ${contact.icon}`}></i>
              <span><a className='contact-source' href={contact.link} target="_blank" rel="noopener noreferrer">{contact.source}</a></span>
            </div>
          ))}
        </div>
        {artist.info && (
          <div className="info">
            {artist.contacts.length > 0  && (<hr></hr>)}
            <p>{artist.info}</p>
          </div>
        )}
        <section className="paragraph center" style={{ alignSelf: 'center' }}>
            <a href="./about-us" className="btn cta_btn">Meet the Team</a>
        </section>
      </div>
    </div>
  );
};

export default ArtistPopup;
