import React, { useState, useEffect } from "react";
import './navBar.css'; // Assuming your CSS file is named Navbar.css

const NavBar = ({ theme = "transparent" }) => {
  const [navTheme, setNavTheme] = useState(theme);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavTheme("solid");
    } else {
      setNavTheme(theme);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [theme]);

  return (
    <header className={`navbar-container ${navTheme}`}>
      <div className="navbar-top-row">
        {/* Social media icons */}
        <a href="https://discord.gg/ZADcjxhw4q" target="_blank" rel="noopener" className="btn-social btn-discord"><i className="fa-brands fa-discord"></i></a>
        <a href="https://www.patreon.com/sinkingshipstudios" target="_blank" rel="noopener" className="btn-social btn-patreon"><i className="fa-brands fa-patreon"></i></a>
        <a href="https://www.instagram.com/sinkingshipstudiosla/" target="_blank" rel="noopener" className="btn-social btn-insta"><i className="fa-brands fa-instagram"></i></a>
        <a href="https://x.com/SinkingShipLA" target="_blank" rel="noopener" className="btn-social btn-twitter"><i className="fa-brands fa-x-twitter"></i></a>
        <a href="https://www.youtube.com/@sinkingshipstudios-la" target="_blank" rel="noopener" className="btn-social btn-youtube"><i className="fa-brands fa-youtube"></i></a>
      </div>
    </header>
  );
};

export default NavBar;
